import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import Login from "./pages/Login";
import Home from "./pages/Home";

export default function App() {
  // axios.defaults.baseURL = "http://localhost:3001";
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get("/auth/login/success", { withCredentials: true })
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => console.log(err.response));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={user ? <Home user={user} /> : <Navigate to="/login" />} />
        <Route exact path="/login" element={user ? <Navigate to="/" /> : <Login />} />
      </Routes>
    </BrowserRouter>
  );
}
