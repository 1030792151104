import { ChakraProvider } from "@chakra-ui/react";
import { Flex, Button } from "@chakra-ui/react";
import React from "react";

const Login = () => {
  const SERVER_URL = "";
  const login = () => {
    window.open(SERVER_URL + "/auth/google", "_self");
  };

  return (
    <ChakraProvider>
      <Flex height={"100vh"} direction={"column"}>
        <Flex alignItems={"center"} justifyContent={"center"} flex={2} height={"100%"}>
          <Button size={"lg"} onClick={login}>
            Log in
          </Button>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
};

export default Login;
