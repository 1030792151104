import { Flex, Button, FormControl, Input, Select, ChakraProvider, useToast } from "@chakra-ui/react";
import React, { useState } from "react";

const Home = (props) => {
  const SERVER_URL = "";
  const toast = useToast();
  const user = props.user;

  const [part, setPart] = useState([{ name: "", email: "@gmail.com", hostel: "",type: "", index: 0 }]);

  const handleChangeName = (index, e) => {
    setPart(part.map((item, i) => (item.index === index ? { ...item, name: e.target.value } : item)));
  };
  const handleChangeEmail = (index, e) => {
    setPart(part.map((item, i) => (item.index === index ? { ...item, email: e.target.value } : item)));
  };
  const handleChangeHostel = (index, e) => {
    setPart(part.map((item, i) => (item.index === index ? { ...item, hostel: e.target.value } : item)));
  };
  const handleChangeType = (index, e) => {
    setPart(part.map((item, i) => (item.index === index ? { ...item, type: e.target.value } : item)));
  };

  return (
    <ChakraProvider>
      <Flex p="1%" boxShadow={"0 2px 4px 0 rgba(0,0,0,.2)"} justifyContent={"end"} width={"100%"}>
        <Button
          size={"lg"}
          onClick={() => {
            window.open(SERVER_URL + "/auth/logout");
          }}
        >
          Logout
        </Button>
        <Button
          size={"lg"}
          onClick={() => {
            window.location = SERVER_URL + '/home';
          }}
          ml={'2%'}
        >
          Home
        </Button>
      </Flex>
      <Flex direction={"column"} p={"1%"}>
        <Flex width={"100%"} justifyContent={"end"}>
          <Button
            onClick={() => {
              if(part.length >= 10) {return;}
              setPart((d) => [...d, { name: "", email: "@gmail.com", hostel: "", index: d.length }]);
            }}
          >
            +
          </Button>
          <Button
            onClick={() => {
              if(part.length===1){return;}
              setPart((d) => d.slice(0,-1));
            }}
          >
            -
          </Button>
        </Flex>
        {part.map((item, i) => (
          <Flex p={"2%"} key={i}>
            <FormControl marginRight={"10%"}>
              <Input
                value={item.name}
                placeholder="Name"
                size={"md"}
                onChange={(e) => {
                  handleChangeName(item.index, e);
                }}
              />
            </FormControl>
            <FormControl marginRight={"10%"}>
              <Input
                value={item.email}
                placeholder="Email"
                size={"md"}
                onChange={(e) => {
                  handleChangeEmail(item.index, e);
                }}
              />
            </FormControl>
            <Select
              placeholder="Select hostel"
              value={item.hostel}
              marginRight={"10%"}
              onChange={(e) => {
                handleChangeHostel(item.index, e);
              }}
            >
              <option value="No Acco">No Acco</option>
              <option value="Off Campus">Off Campus</option>
              <option value="Faculty Quaters">Faculty Quaters</option>
              <option value="AH1">AH1</option>
              <option value="AH2">AH2</option>
              <option value="AH3">AH3</option>
              <option value="AH4">AH4</option>
              <option value="AH5">AH5</option>
              <option value="AH6">AH6</option>
              <option value="AH7">AH7</option>
              <option value="AH8">AH8</option>
              <option value="AH9">AH9</option>
              <option value="CH1">CH1</option>
              <option value="CH2">CH2</option>
              <option value="CH3">CH3</option>
              <option value="CH4">CH4</option>
              <option value="CH5">CH5</option>
              <option value="CH6">CH6</option>
              <option value="CH7">CH7</option>
              <option value="DH1">DH1</option>
              <option value="DH2">DH2</option>
              <option value="DH3">DH3</option>
              <option value="DH4">DH4</option>
              <option value="DH5">DH5</option>
              <option value="DH6">DH6</option>
            </Select>
            <Select
              placeholder="Select Type"
              value={item.type}
              marginRight={"10%"}
              onChange={(e) => {
                handleChangeType(item.index, e);
              }}
            >
              <option value="Only Sports">Only Sports</option>
              <option value="Only Nights">Only Nights</option>
              <option value="Sports + Nights">Sports + Nights</option>
            </Select>
          </Flex>
          
        ))}
        <Button
          width={"10%"}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            for(let i in part){
              if(part[i].name==="" || part[i].email==="" || part[i].hostel==="" || part[i].type===""){
                alert('invalid input')
                return
              }
            }
            const emailPromise = new Promise(async (resolve, reject) => {
              const newUsers = part.map(({index, ...parts}) => parts)
              let body = {"users":newUsers,"approverName":user.name,"approverEmail":user.email}
              // console.log(JSON.stringify(body))
              const response = await fetch(`${SERVER_URL}/createTickets`, {
                method: 'POST',
                body: JSON.stringify(body),
                credentials:'include',
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              if (response.status===201){
                setPart([{ name: "", email: "@gmail.com", hostel: "",type:"", index: 0 }]);
                console.log(part);
                resolve();
              }else{
               reject();
              };
            });
            toast.promise(emailPromise, {
              success: { title: "Emails sent" },
              error: { title: "Error in sending emails" },
              loading: { title: "Sending emails", description: "Please wait" },
            });
          }}
        >
          Submit
        </Button>
      </Flex>
    </ChakraProvider>
  );
};

export default Home;
